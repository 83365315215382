<template>
  <div :class="$style.summary">
    <start-process-panel
      :side-panel-name="sidePanelName"
      :process-id="startProcessId"
    />
    <div :class="$style.controls">
      <ProcessControls
        :hide="[loading && 'refresh']"
        @edit="edit({ id: processId })"
        @start="start({ id: processId })"
        @delete="removeProcess"
      />
    </div>

    <div :class="$style.subtitle">
      Process runs
      <button
        type="button"
        title="Refresh"
        :class="$style.refresh"
        :disabled="loading"
        @click="refreshJobs({ processId })"
      >
        <font-awesome-icon
          :class="{ [$style.rotate]: loading }"
          :icon="['fal', 'rotate']"
        />
      </button>
    </div>

    <div
      v-if="jobs.length"
      :class="$style.list"
    >
      <b-table
        :data="jobs"
        hoverable
        :class="$style.table"
        @click="selectJob"
      >
        <b-table-column
          v-slot="{ row: { started } }"
          sortable
          field="started"
          label="Start"
        >
          {{ formatDate({ date: started, format: 'MMM DD, YYYY, hh:mm A' }) }}
          ({{ fromDate({ date: started }) }})
        </b-table-column>

        <b-table-column
          v-slot="{ row: { duration } }"
          label="Duration"
          field="duration"
          sortable
        >
          {{ formatDateUTC({ date: duration, format: 'HH:mm:ss' }) }}
        </b-table-column>

        <b-table-column
          v-slot="{ row: { status } }"
          sortable
          field="status"
          label="Status"
          class="abs"
        >
          <div :class="[$style.status, status === 'Failed' && $style['status--failed']]">
            {{ status === 'Finished' ? 'Succeeded' : status }}
          </div>
        </b-table-column>
      </b-table>
    </div>

    <div v-else>
      You didn't start this process yet. Click “Start” to run your process for the first time.
    </div>
    <infinite-loading
      :identifier="infiniteId"
      @infinite="loadMoreItems"
    >
      <span slot="no-more" />
      <div slot="spinner">
        <spinner />
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import InfiniteLoading from 'vue-infinite-loading';
import { useProcessesStore } from '@/stores/index.js';
import processMixin from './mixins/process-mixin.js';
import ProcessControls from './components/process-controls.vue';
import Spinner from '@/components/spinner.vue';
import { formatDate, fromDate, formatDateUTC } from '@/utils/index.js';

export default {
  name: 'ProcessSummary',
  components: {
    ProcessControls,
    InfiniteLoading,
    Spinner,
  },
  mixins: [processMixin],
  props: {
    processId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      startProcessId: null,
      infiniteId: +new Date(),
      skipInfinityLoad: false,
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processName']),
    ...mapWritableState(useProcessesStore, ['jobs', 'isLastJobsPage', 'continuationToken']),
  },
  watch: {
    processId(processId) {
      this.readJobs({ processId });
    },
  },
  created() {
    this.continuationToken = null;
  },
  destroyed() {
    this.continuationToken = null;
    this.jobs = [];
  },
  methods: {
    ...mapActions(useProcessesStore, ['readJobs']),

    formatDate,
    fromDate,
    formatDateUTC,
    selectJob({ jobId, namePrefix }) {
      return this.$router.push({ name: 'DocumentsProcessJob', params: { jobId }, query: { namePrefix } });
    },

    async refreshJobs() {
      this.continuationToken = null;
      this.skipInfinityLoad = true;

      this.loading = true;
      await this.readJobs({ processId: this.processId });
      this.loading = false;

      this.infiniteId += 1;
    },

    async removeProcess() {
      await this.remove({
        id: this.processId, processName: this.processName, goToProcessList: true,
      });
    },

    async loadMoreItems($state) {
      if (this.skipInfinityLoad) {
        this.skipInfinityLoad = false;
      } else {
        this.loading = true;
        await this.readJobs({ processId: this.processId, isAppend: true });
        this.isAppend = true;
        this.loading = false;
      }

      $state.loaded();

      if (this.isLastJobsPage) {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" module>
.summary {
  .controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 36px;
  }

  .table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .status {
    &--failed {
      color: var(--color-danger);
    }
  }
}

.refresh {
  position: relative;
  right: 5px;
  bottom: 1px;
  border: none;
  padding: 10px;
  background: transparent;
  font-size: 16px;
  color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &:hover {
    color: hsl(0deg, 0%, 4%);
  }
}

.rotate {
  animation-duration: 500ms;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media (max-width: 768px) {
  .summary {
    :global(.table-mobile-sort) {
      display: none;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
